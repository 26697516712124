<script lang="ts" setup>
const { t } = useI18n();
const localePath = useLocalePath();

const query = {
  relationships: "breakdowns,memberships,organizations",
  role: "owner,admin",
  limit: 100,
};
const { state: teamsState } = useAsyncState(
  () => getCachedUserTeamsList(query),
  { data: [], total: 0 },
  { shallow: false },
);
</script>

<template>
  <div class="min-h-screen bg-neutral-light-50 dark:bg-neutral-dark-900 dark:text-neutral-dark-200">
    <AppLayout
      class="max-w-full"
      has-divider
    >
      <template #pageTitle>
        <UButton
          variant="clean"
          class="text-black dark:text-white p-0 max-lg:mt-6"
          :icon="{ name: 'grid', class: 'text-neutral-light-700' }"
          :label="t('labels.go_to_events')"
          :to="localePath('/events/')"
        />
      </template>

      <div class="max-w-[1100px] w-full mx-auto">
        <h1 class="mt-4 mb-10 text-3xl font-bold text-black dark:text-white">
          {{ t("labels.account_settings") }}
        </h1>

        <div class="flex flex-col lg:grid lg:grid-cols-[240px_1fr] gap-6">
          <nav>
            <AccountMenu :has-breakdowns="!!teamsState.data?.length" />
          </nav>
          <main>
            <NuxtPage
              :teams="teamsState.data"
              keepalive
            />
          </main>
        </div>
      </div>
    </AppLayout>
  </div>
</template>
